import { Close } from "@storybook/assets";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
export interface AlertProps {
  style?: React.CSSProperties;
  header?: string;
  headerStyle?: React.CSSProperties;
  message: React.ReactNode;
  messageStyle?: React.CSSProperties;
  type?: "info" | "error" | "warning" | "confirm" | "remove" | "yesno";
  buttonWrapperStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  removeLabel?: string;
  cancelLabel?: string;
  okLabel?: string;
  noLabel?: string;
  submit?: () => void;
  unSubmit?: () => void;
}
const Alert = () => {
  const [alert, setAlert] = useState<AlertProps>();
  const handler = useCallback(({ detail }: CustomEvent<AlertProps>) => {
    setAlert(detail);
  }, []);

  useEffect(() => {
    window.addEventListener("toastAlert", handler as any);
    return () => {
      window.removeEventListener("toastAlert", handler as any);
    };
  }, [handler]);
  if (alert) {
    return (
      <S.Alert>
        <S.Wrapper>
          <S.Card style={alert.style}>
            <S.Header style={alert.headerStyle}>
              <S.HeaderText>{alert.header ?? ""}</S.HeaderText>
              <S.Close onClick={() => setAlert(undefined)} />
            </S.Header>
            <S.Context style={alert.messageStyle}>{alert.message}</S.Context>
            {alert.type !== "yesno" ? (
              <S.ButtonWrapper style={alert.buttonWrapperStyle}>
                <>
                  {(alert.type === "confirm" || alert.type === "remove") && (
                    <S.Button
                      style={alert.buttonStyle}
                      className="cancel"
                      type="button"
                      onClick={() => setAlert(undefined)}
                    >
                      {alert.cancelLabel ?? "닫기"}
                    </S.Button>
                  )}
                  {alert.type !== "remove" ? (
                    <S.Button
                      style={alert.buttonStyle}
                      className={alert.type ?? "info"}
                      type="button"
                      onClick={() => {
                        alert.submit && alert.submit();
                        setAlert(undefined);
                      }}
                    >
                      {alert.okLabel ?? "확인"}
                    </S.Button>
                  ) : (
                    <S.Button
                      style={alert.buttonStyle}
                      className="remove"
                      type="button"
                      onClick={() => {
                        alert.submit && alert.submit();
                        setAlert(undefined);
                      }}
                    >
                      {alert.removeLabel ?? "삭제하기"}
                    </S.Button>
                  )}
                </>
              </S.ButtonWrapper>
            ) : (
              <S.ButtonWrapper style={alert.buttonWrapperStyle}>
                <>
                  <S.Button
                    style={alert.buttonStyle}
                    className="cancel"
                    type="button"
                    onClick={() => setAlert(undefined)}
                  >
                    {alert.cancelLabel ?? "닫기"}
                  </S.Button>
                  <S.Button
                    style={alert.buttonStyle}
                    className="remove"
                    type="button"
                    onClick={() => {
                      alert.unSubmit && alert.unSubmit();
                      setAlert(undefined);
                    }}
                  >
                    {alert.noLabel ?? "아니요"}
                  </S.Button>
                  <S.Button
                    style={alert.buttonStyle}
                    className="info"
                    type="button"
                    onClick={() => {
                      alert.submit && alert.submit();
                      setAlert(undefined);
                    }}
                  >
                    {alert.okLabel ?? "예"}
                  </S.Button>
                </>
              </S.ButtonWrapper>
            )}
          </S.Card>
        </S.Wrapper>
      </S.Alert>
    );
  }
  return <></>;
};
const S = {
  Alert: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(52, 52, 52, 0.4);
    z-index: 99999;
    overflow-y: scroll;
  `,
  Wrapper: styled.div`
    position: relative;
    /* margin-left: 270px;
        margin-top: 110px;
        width: calc(100% - 270px);
        height: calc(100% - 110px); */
    width: 100%;
    height: 100%;
  `,
  Card: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    min-height: 240px;
    background: #ffffff;
    box-shadow: 2px 4px 20px 10px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
  `,
  Close: styled(Close)`
    cursor: pointer;
  `,
  Header: styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  HeaderText: styled.div`
    flex: 1;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
  `,
  Context: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.2px;

    color: #888888;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  `,
  Button: styled.button`
    width: 112px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;

    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: #ffffff;
    cursor: pointer;
    &.info,
    &.confirm {
      background-color: #5a9afb;
    }
    &.cancel {
      background-color: #363738;
    }
    &.remove {
      background-color: #e04747;
    }
    &.error {
      background-color: #e96666;
    }
    &.warning {
      background-color: #e9a266;
    }
  `,
};
const alertFnc = (detail: AlertProps) => {
  window.dispatchEvent(
    new CustomEvent("toastAlert", {
      detail,
    }),
  );
};
export { Alert, alertFnc };
