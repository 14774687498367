import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import GlobalStyle from "@styles/global";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import client from "@graphql/apollo-client";
import { Alert } from "@storybook/alert";
import { PopupComponent } from "@storybook/popup";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <GlobalStyle />
      <App />
    </BrowserRouter>
    <Alert />
    <PopupComponent.PopupComponent />
  </ApolloProvider>,
);
