import Spinner from "@storybook/spinner";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthProvider from "./auth";
import {
  BookApproveDetailForWindow,
  BookDetailForWindow,
  LanguageQualificationDetailForWindow,
  LearningQualificationDetailForWindow,
  LoginPage,
  MainPage,
  MasterLessonRegisterForWindow,
  MasterLessonsDetailForWindow,
  NotFoundPage,
  PartnershipCalculateDetailForWindow,
  PublisherApproveDetailForWindow,
  PublisherCalculateDetailForWindow,
  PublisherDetailForWindow,
  TeacherDetailForWindow,
  UserDetailPageForWindow,
} from "./pages";

function App() {
  return (
    <AuthProvider>
      <React.Suspense fallback={<Spinner />}>
        <Routes>
          {/* 메인페이지, 로그인, 404 */}
          <Route>
            <Route path="/" element={<MainPage />} />
            <Route path="/:tabId" element={<MainPage />} />
          </Route>
          <Route path="/detail/user/:id" element={<UserDetailPageForWindow />} />
          <Route path="/detail/teacher/:id" element={<TeacherDetailForWindow />} />
          <Route path="/detail/publisher/:id" element={<PublisherDetailForWindow />} />
          <Route path="/detail/learning/qualification/:id" element={<LearningQualificationDetailForWindow />} />
          <Route path="/detail/language/qualification/:id" element={<LanguageQualificationDetailForWindow />} />
          <Route path="/detail/publisher/approve/:id" element={<PublisherApproveDetailForWindow />} />
          <Route path="/detail/book/approve/:id" element={<BookApproveDetailForWindow />} />
          <Route path="/detail/lesson/:id" element={<MasterLessonsDetailForWindow />} />
          <Route path="/detail/lesson/:id/modify" element={<MasterLessonRegisterForWindow />} />
          <Route path="/detail/book/:id" element={<BookDetailForWindow />} />
          <Route path="/detail/publisher/calculate/:id" element={<PublisherCalculateDetailForWindow />} />
          <Route path="/detail/partner/calculate/:id" element={<PartnershipCalculateDetailForWindow />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </React.Suspense>
    </AuthProvider>
  );
}
export default App;
