import { ApolloClient, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { Observable, relayStylePagination } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RefreshTokenDocument } from "./graphql";

const httpLink = createHttpLink({
  uri: "https://api.fineteacher.com/graphql",
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("TOKEN");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

function getNewToken() {
  return new Observable<string>(subscriber => {
    const refreshToken = localStorage.getItem("REFRESH_TOKEN");
    client
      .mutate({ mutation: RefreshTokenDocument, variables: { refreshToken: refreshToken } })
      .then(res => {
        localStorage.setItem("TOKEN", res.data.reissueToken.accessToken);
        localStorage.setItem("REFRESH_TOKEN", res.data.reissueToken.refreshToken);
        subscriber.next(res.data.reissueToken.accessToken);
        subscriber.complete();
      })
      .catch(() => {
        subscriber.error(new Error("FAILED_REFRESH"));
      });
  });
}

const authErrorLink = onError(({ graphQLErrors, operation, forward }) => {
  console.log(graphQLErrors);
  if (graphQLErrors != null) {
    for (const graphQLError of graphQLErrors) {
      switch (graphQLError.extensions.code) {
        case "UNAUTHENTICATED":
          return getNewToken().flatMap(token => {
            const prevContext = operation.getContext();
            operation.setContext({
              ...prevContext,
              headers: {
                ...prevContext.headers,
                authorization: token ? `Bearer ${token}` : undefined,
              },
            });

            return forward(operation);
          });
      }
    }
  }
});

const errorLink = onError(({ networkError }) => {
  if (networkError != null && networkError.message === "FAILED_REFRESH") {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("REFRESH_TOKEN");
    window.location.href = "/login";
    return;
  }
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        users: relayStylePagination(["filter", "sort", "first", "offset"]),
      },
    },
  },
});

const client = new ApolloClient({
  cache,
  link: from([errorLink, authErrorLink, authLink.concat(httpLink)]),
  connectToDevTools: true,
});

export default client;
