import styled, { css } from "styled-components";
interface SpinnerProps {
  type?: "route" | "main";
}
const Spinner = (props: SpinnerProps) => {
  const { type = "route" } = props;
  return (
    <Loading type={type}>
      <div className="spinner" />
    </Loading>
  );
};
export default Spinner;
const Loading = styled.div<SpinnerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ type }) =>
    type === "main" &&
    css`
      top: auto;
      left: auto;
      width: calc(100% - 270px);
      height: calc(100% - 120px);
      background-color: #00000001;
    `}
  background-color: #00000030;
  z-index: 9999;
  .spinner {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: #f19022;
    border-bottom-color: #f19022;
    animation: spinner 0.8s ease infinite;
  }
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
