import React from "react";
export const MainPage = React.lazy(() => import("./main"));
export const LoginPage = React.lazy(() => import("./login"));
export const NotFoundPage = React.lazy(() => import("./404"));

//회원관리
export const UsersPage = React.lazy(() => import("./member/users"));
export * from "./member/users/detail";
export const TeachersPage = React.lazy(() => import("./member/teachers"));
export * from "./member/teachers/detail";
export const PublisherPage = React.lazy(() => import("./member/publisher"));
export * from "./member/publisher/detail";
export const SecessionUserPage = React.lazy(() => import("./member/secessionUser"));
export * from "./member/secessionUser/detail";

//권한관리
export const AuthorityPage = React.lazy(() => import("./authority/authorityMangement"));

//수업관리 lesson
export const MonitoringPage = React.lazy(() => import("./lesson/monitoring"));
export const MasterLessonPage = React.lazy(() => import("./lesson/masterLesson"));
export * from "./lesson/masterLesson/detail";

//교재관리 bookMangement
export const BooksPage = React.lazy(() => import("./bookManagement/book"));
export * from "./bookManagement/book/detail";
export const CategoryPage = React.lazy(() => import("./bookManagement/category"));
export const CategoryDetailPage = React.lazy(() => import("./bookManagement/category/detail"));
// 디자인관리
export const ContentPage = React.lazy(() => import("./design/content"));
export const ContentDetailPage = React.lazy(() => import("./design/content/detail"));
export const PopupPage = React.lazy(() => import("./design/popup"));
export const PopupDetailPage = React.lazy(() => import("./design/popup/detail"));
export const BookRegisterPage = React.lazy(() => import("./bookManagement/book/detail/book-register"));

// 정산 calculate
export const PublisherCalculatePage = React.lazy(() => import("./calculate/publisher"));
export * from "./calculate/publisher/detail";
export const TeacherCalculatePage = React.lazy(() => import("./calculate/teacher"));

export const PartnershipCalculatePage = React.lazy(() => import("./calculate/partner"));
export * from "./calculate/partner/detail";

//운영관리 operationMangement
export const CacheMangementPage = React.lazy(() => import("./operationMangement/cacheMangement")); //캐시관리
export const CacheMangementDetailPage = React.lazy(() => import("./operationMangement/cacheMangement/detail"));
export const WithdrawMoneyPage = React.lazy(() => import("./operationMangement/withtdrawMoney")); //출금관리
export const WithdrawMoneyDetail = React.lazy(() => import("./operationMangement/withtdrawMoney/detail"));
export const FriendRecommendationPage = React.lazy(() => import("./operationMangement/friendRecommendation")); //친구추천/제휴 관리
export const FriendRecommendationDetailPage = React.lazy(
  () => import("./operationMangement/friendRecommendation/detail"),
);
export const ReviewPage = React.lazy(() => import("./operationMangement/review"));
export const ReviewDetailPage = React.lazy(() => import("./operationMangement/review/detail"));
export const ReportMangementPage = React.lazy(() => import("./operationMangement/reportMangement"));
export const ReportMangementPageDetail = React.lazy(() => import("./operationMangement/reportMangement/detail"));
export const AnnoucementPage = React.lazy(() => import("./operationMangement/announcement")); //공지사항
export const AnnoucementDetail = React.lazy(() => import("./operationMangement/announcement/detail"));
export const FaqPage = React.lazy(() => import("./operationMangement/faq")); //FAQ
export const FaqDetail = React.lazy(() => import("./operationMangement/faq/detail"));
export const ForbiddenWordPage = React.lazy(() => import("./operationMangement/forbiddenWordMangement")); //금칙어 관리
export const ForbiddenWordDetail = React.lazy(() => import("./operationMangement/forbiddenWordMangement/detail"));
export const ForbiddenWordMonitoringPage = React.lazy(() => import("./operationMangement/forbiddenWorMonitoring")); //금칙어모니터링
export const ForbiddenWordMonitoringPageDetail = React.lazy(
  () => import("./operationMangement/forbiddenWorMonitoring/detail"),
);
export const ChattingPage = React.lazy(() => import("./operationMangement/chatting"));
export const QuestionsPage = React.lazy(() => import("./operationMangement/questions"));
export const PolicyPage = React.lazy(() => import("./operationMangement/policy"));
export const PolicyDetail = React.lazy(() => import("./operationMangement/policy/detail"));
export const FaqCategoryPage = React.lazy(() => import("./operationMangement/faq/category"));
export const QuestionDetailPage = React.lazy(() => import("./operationMangement/questions/detail"));

//승인요청 관리 approve
export const BookApprovePage = React.lazy(() => import("./approve/book"));
export * from "./approve/book/detail";
export const BrandApprovePage = React.lazy(() => import("./approve/brand"));
export const PublisherApprovePage = React.lazy(() => import("./approve/publisher"));
export * from "./approve/publisher/detail";
export const LearningApprovePage = React.lazy(() => import("./approve/learningQualification"));
export * from "./approve/learningQualification/detail";
export const LanguageApprovePage = React.lazy(() => import("./approve/languageQualification"));
export * from "./approve/languageQualification/detail";
