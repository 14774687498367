import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html,
    body {
        overflow: hidden;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #e5e5e5;
        /* font-size: 75%; */
    }

    * {
        box-sizing: border-box;
        font-family: "Spoqa Han Sans Neo", sans-serif;
        font-style: normal;
    }

    input,
    textarea,
    button {
        outline: none;
    }

    html,
    body,
    div,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    address,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    input,
    select,
    textarea {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    @keyframes spinner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

`;
export default GlobalStyle;
